.input {
	height: 34px;
	width: 220px;
	padding: 0 12px;
	font-size: 15px;
	font-family: inherit;
	background-color: transparent;
	border: none;
	color: #ddd;
}

.input:focus {
	outline: none;
}

.input::placeholder {
	color: #aaa;
}

.inputInvalid {
	color: #e65757;
}

.link {
	color: #2996da;
	text-decoration: underline;
}
