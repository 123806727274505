.buttonWrapper {
	display: inline-block;
}

.button {
	background: rgb(240, 240, 240);
	color: #ddd;
	font-size: 18px;
	border: 0;
	padding-top: 5px;
	vertical-align: bottom;
	height: 34px;
	width: 40px;
	border-radius: 4px;
}

.button svg {
	fill: #333;
	display: unset;
	vertical-align: unset;
}

.button:hover,
.button:focus {
	background: rgb(223, 223, 223);
	cursor: pointer;
	outline: 0; /* reset for :focus */
}

.active {
	color: #ff0083;
}

.active svg {
	fill: #ff0083;
}
