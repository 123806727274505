.toolbar {
	border: 0px solid #fff;
	background: rgb(240, 240, 240);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	z-index: 2;
	box-sizing: border-box;
}

.toolbar:after {
	border-color: #fefefe;
	border-top-color: #fff;
	border-width: 4px;
	margin-left: -4px;
}

.toolbar:before {
	border-color: #fefefe;
	border-top-color: #fff;
	border-width: 6px;
	margin-left: -6px;
}
