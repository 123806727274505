.editor {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	border-width: 1px;
	border-radius: 5px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.12);

	cursor: text;
	background: #fefefe;
	word-wrap: break-word;
	word-break: break-all;
	flex: 1;
}

.editor :global(.DraftEditor-root) {
	display: flex;
	flex: 1;
	height: 100%;
	overflow-y: auto;
	position: relative;
}

.editor :global(.DraftEditor-editorContainer) {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 16px;
	z-index: 12;
}

.editor :global(.public-DraftEditor-content) {
	flex: 1;
	word-break: normal;
}
.editor :global(.public-DraftEditorPlaceholder-root) {
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 0;
}

.options {
	margin-bottom: 20px;
}
